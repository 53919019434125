
.hero {
  text-align: center;
  margin-top: 50px;
  padding: 30px;
  background-color: #4182e4;
  color: white;
  border-radius: 30px;

  -webkit-box-shadow: 2px 2px 4px 1px #fff;
  box-shadow: 2px 2px 4px 1px #fff;

  h1 {
    color: white;
  }
}

.reason {
  padding: 20px;

  h2 {
    margin-bottom: 30px;
    text-align: center;
  }
}

.reason-left {
  width: 50%;
  float: left;
}

.reason-right {
  width: 50%;
  float: right;
}

.reason-img {
  border-width: 1px;
  border-color: black;
  border-style: solid;
  margin: 10px;
}

.bottom-cta {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 100px 0;

  h2 {
    margin-bottom: 20px;
  }
}