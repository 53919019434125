// - - - - - - - - - - - - - - - - - -

// Portfolio

// - - - - - - - - - - - - - - - - - -

.portfolio {
	padding: 0 $grid-spacing/2;
}

.portfolio-wrap {
	
}

.portfolio-item {
	position: relative;
	padding: $grid-spacing/2;
	float: left;
	width: 50%;
}

.portfolio-item__link {
	position: relative;
	display: block;

	@include mq(tabletl) {

		&:hover {

			.portfolio-item__content {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

.portfolio-item__image {
	width: 100%;
}

.portfolio-item__content {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba($overlay-background-color, $overlay-opacity);

	opacity: 0;
	visibility: hidden;
	@include transition(all 0.2s ease-in-out);
}

.portfolio-item__info {
	text-align: center;
}

.portfolio-item__title {
	color: $overlay-text-color;
	font-size: $grid-item-title-small;
	font-weight: $grid-item-title-weight;
	letter-spacing: $grid-item-title-spacing;

	@include mq(tabletp) {
		font-size: $grid-item-title-medium;
	}

	@include mq(laptop) {
		font-size: $grid-item-title-large;
	}
}

.portfolio-item__subtitle {
	margin-top: 5px;
	color: rgba($overlay-text-color, 0.7);
	font-size: $grid-item-subtitle-small;
	font-weight: $grid-item-subtitle-weight;
	letter-spacing: $grid-item-subtitle-spacing;

	@include mq(tabletp) {
		font-size: $grid-item-subtitle-medium;
	}

	@include mq(laptop) {
		font-size: $grid-item-subtitle-large;
	}
}
