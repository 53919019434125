// - - - - - - - - - - - - - - - - - -

// Footer

// - - - - - - - - - - - - - - - - - -



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Footer

.footer {
	@include transition(all 0.4s ease-in-out 0.8s);
	padding: 40px 0;

	@include mq(tabletp) {
		padding: 50px 0;
	}

	@include mq(tabletl) {
		padding: 60px 0;
	}

	@include mq(laptop) {
		padding: 80px 0;
	}
}

.footer__logo {
	display: block;
	width: $footer-logo-width;
}

.footer__title {
	font-size: $sitetitle-small;
	font-weight: $sitetitle-weight;
	letter-spacing: $sitetitle-spacing;
	line-height: 1;
	color: $text-medium-color;

	&:hover {
		color: $text-dark-color;
	}

	@include mq(tabletp) {
		font-size: $sitetitle-medium;
	}

	@include mq(laptop) {
		font-size: $sitetitle-large;
	}
}

.footer__text {
	color: $text-light-color;
}

.footer__copyright {
	display: block;
	margin-top: 10px;
	font-size: 13px;
	color: $text-light-color;

	a {
		margin-left: 10px;
		color: $text-light-color;
		font-weight: $regular-weight;

		&:hover {
			color: $text-medium-color;
		}
	}

	span {
		margin-top: 10px;
		display: inline-block;
	}

	@include mq(tabletp) {

		span {
			margin-top: 0;
		}
	}
}

.footer .socials {
	margin-top: 25px;

	@include mq(laptop) {
		position: absolute;
		top: 0;
		right: 0;
		margin-top: 0;
	}
}