// - - - - - - - - - - - - - - - - - -

// Header + menu

// - - - - - - - - - - - - - - - - - -



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Header

.header {
	margin-top: 40px;

	@include mq(tabletp) {
		margin-top: 50px;
	}

	@include mq(tabletl) {
		margin-top: 60px;
	}

	@include mq(laptop) {
		margin-top: 80px;
	}
}

.header__logo {
	display: block;
	width: $logo-width;
	max-width: 80%;

	@include mq(tabletp) {
		float: left;
		max-width: 50%;
	}
}

.header__logo__img {
	width: 100%;
	height: auto;
}

.header__title {
	font-size: $sitetitle-small;
	font-weight: $sitetitle-weight;
	letter-spacing: $sitetitle-spacing;
	line-height: 1;
	color: $text-dark-color;

	&:hover {
		color: $accent-color;
	}

	@include mq(tabletp) {
		font-size: $sitetitle-medium;
	}

	@include mq(laptop) {
		font-size: $sitetitle-large;
	}
}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Menu

.menu {
	
	@include mq(tabletl) {
		position: absolute;
		top: 50%;
		right: 20px;
		@include translate(0,-50%);
	}
}

.menu__toggle {
	position: absolute;
	z-index: 100;
	top: 50%;
	right: 30px;
	@include translate(0,-50%);
	cursor: pointer;
	font-size: 15px;
	line-height: 20px;
	color: $text-dark-color;

	@include mq(tabletp) {
		right: 0;
	}
	
	@include mq(tabletl) {
		display: none;
	}
}

.menu__toggle__icon {
	float: right;
	position: relative;
	width: 24px;
	height: 20px;
	margin-left: 8px;

	span, span:before, span:after {
		content: '';
		position: absolute;
		top: 8px;
		left: 0;
		width: 100%;
		height: 3px;
		@include transition(all 0.1s);
		background: $text-dark-color;
	}

	span:before {
		top: -8px;
	}

	span:after {
		top: 8px;
	}
}

.menu--open .menu__toggle__icon {

	span:before, span:after {
		background: $overlay-text-color !important;
	}
		
	span {
		background-color: transparent !important;
	}
	
	span:before,
	span:after {
		top: 0;
	}
	
	span:before {
		@include rotate(45);
	}

	span:after {
		@include rotate(-45);
	}
}

.menu__wrap {
	position: fixed;
	z-index: 90;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba($overlay-background-color,0.95);
	overflow: auto;

	visibility: hidden;
	opacity: 0;
	@include transition(all 0.2s ease-in-out);

	@include mq(tabletl) {
		position: relative;
		background: transparent;
		visibility: visible;
		overflow: visible;
		opacity: 1;
		@include transition(none);
	}
}

.menu--open .menu__wrap {
	visibility: visible;
	opacity: 1;
}

.menu__list {
	list-style: none;
	margin: 0 auto;
	padding: 30px;

	@include mq(tabletp) {
		padding: 40px 0 0;
		max-width: 70%;
	}

	@include mq(tabletl) {
		max-width: none;
		margin: 0;
		padding: 0;
	}
}

.menu__list__item {

	@include mq(tabletl) {
		display: inline-block;
		margin-left: 20px;

		&:first-child {
			margin-left: 0;
		}
	}
}

.menu__list__item__link {
	font-size: $menu-small;
	font-weight: $menu-weight;
	color: $overlay-text-color;
	padding: 10px 0;

	&:hover {
		color: $overlay-accent-color;
	}

	@include mq(tabletl) {
		padding: 0 0 3px;
		font-size: $menu-medium;
		color: $text-dark-color;
		border-bottom: 2px solid transparent;

		&:hover {
			color: $accent-color;
		}
	}

	@include mq(laptop) {
		font-size: $menu-large;
	}
	
	&.active-link {
		color: $overlay-accent-color;

		@include mq(tabletl) {
			color: $text-dark-color;
			border-color: $accent-color;
		}
	}
}

.menu__button {
	margin-top: 15px;
	padding: 15px 20px 16px;
	font-size: 13px;

	@include mq(tabletl) {
		margin-top: 0;
		padding: 11px 20px 12px;
		font-size: 15px;
	}
}

.buttons--circular {

	.menu__button {
		border-radius: 24px;

		@include mq(tabletl) {
			border-radius: 21px;
		}
	}
}
