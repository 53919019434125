// - - - - - - - - - - Foundations

@import "_reset";
@import "_mixins";
@import "_plugins";



// - - - - - - - - - - Variables


// Colors

$background-color: #ffffff;
$background-alt-color: #f4f5f6;
$border-color: #dddddd;

$text-dark-color: #021331;
$text-medium-color: #495388;
$text-light-color: #6ec8cb;

$accent-color: #d9231a;

$overlay-background-color: #d9231a;
$overlay-text-color: #ffffff;
$overlay-accent-color: #d9231a;

$error-color: #D64541;


// Fonts

$body-font: "Muli", sans-serif;
$title-font: "Muli", sans-serif;

$regular-weight: 400;
$bold-weight: 600;


// Typography

$sitetitle-small: 25px;
$sitetitle-medium: 30px;
$sitetitle-large: 35px;
$sitetitle-weight: 700;
$sitetitle-spacing: -0.02em;

$menu-small: 30px;
$menu-medium: 15px;
$menu-large: 17px;
$menu-weight: 600;
$menu-spacing: 0em;

$grid-item-title-small: 22px;
$grid-item-title-medium: 30px;
$grid-item-title-large: 35px;
$grid-item-title-weight: 700;
$grid-item-title-spacing: -0.02em;

$grid-item-subtitle-small: 15px;
$grid-item-subtitle-medium: 17px;
$grid-item-subtitle-large: 19px;
$grid-item-subtitle-weight: 400;
$grid-item-subtitle-spacing: 0em;

$h1-small: 25px;
$h1-medium: 35px;
$h1-large: 45px;
$h1-weight: 700;
$h1-spacing: -0.02em;
$h1-lineheight: 1.2;

$h2-small: 22px;
$h2-medium: 30px;
$h2-large: 35px;
$h2-weight: 700;
$h2-spacing: -0.02em;
$h2-lineheight: 1.2;

$h3-small: 22px;
$h3-medium: 25px;
$h3-large: 30px;
$h3-weight: 600;
$h3-spacing: -0.02em;
$h3-lineheight: 1.3;

$h4-small: 17px;
$h4-medium: 22px;
$h4-large: 22px;
$h4-weight: 700;
$h4-spacing: 0em;
$h4-lineheight: 1.6;

$h5-small: 17px;
$h5-medium: 20px;
$h5-large: 20px;
$h5-weight: 700;
$h5-spacing: 0em;
$h5-lineheight: 1.6;

$h6-small: 17px;
$h6-medium: 20px;
$h6-large: 20px;
$h6-weight: 700;
$h6-spacing: 0em;
$h6-lineheight: 1.6;

$p-small: 15px;
$p-medium: 17px;
$p-large: 19px;
$p-weight: 400;
$p-spacing: 0em;
$p-lineheight: 1.6;

$blockquote-small: 25px;
$blockquote-medium: 35px;
$blockquote-large: 45px;
$blockquote-weight: 300;
$blockquote-spacing: -0.02em;
$blockquote-lineheight: 1.6;

$button-weight: 600;
$button-spacing: 0em;


// Logo
$logo-width: 200px;
$footer-logo-width: 200px;


// Grid item
$overlay-opacity: 0.8;
$grid-spacing: 10px;



// - - - - - - - - - - Global styles

@import "_basic";

@import "_includes/_header";
@import "_includes/_footer";

@import "_includes/_content";
@import "_includes/_socials";
@import "_includes/_gallery";
@import "_includes/_syntax";
@import "_includes/_contact-form";



// - - - - - - - - - - Section styles

@import "_includes/_portfolio";
@import "_includes/_blog";
@import "_includes/_landing-page.scss";


